<template>
  <div class="feedback-contaner">
    <!-- 关于我 start -->
    <el-row class="about-box">
      <el-row class="contaner">
        <!-- 关于我们内容 start -->
        <el-row class="about">
          <!-- <el-row
            class="header"
            type="flex"
            align="middle"
            justify="space-between"
          >
            <p class="left">意见反馈</p>
            <p class="right" @click="backHandle"></p>
          </el-row> -->
          <p class="c3 f18">反馈类型</p>
          <div class="btn">
            <button
              class="f14 c9 mr32"
              v-for="(item, index) in feedBackList"
              :key="index"
              :class="{ active: active == index }"
              @click="changeActive(index, item)"
            >
              {{ item.typeTitle }}
            </button>
          </div>
          <!-- <el-divider></el-divider> -->
          <el-form
            label-width="0px"
            :model="ruleForm"
            :status-icon="false"
            :rules="rules"
            ref="ruleForm"
            class="demo-ruleForm"
            :hide-required-asterisk="true"
          >
            <!-- 反馈内容: -->
            <el-form-item required prop="feedfackContant">
              <p class="f18 c3">反馈内容</p>
              <p class="f14 c3">您的意见是我们前进的动力：</p>
              <el-input
                class="mt18"
                type="textarea"
                :rows="4"
                v-model="ruleForm.feedfackContant"
                placeholder="请输入您的意见或反馈"
              ></el-input>
            </el-form-item>
            <!-- 反馈内容:结束 -->
            <!-- <el-divider></el-divider> -->
            <!-- 添加文件 -->
            <el-form-item>
              <div class="img mt16">
                <!-- <img
              @click="uploadFile"
              src="@/assets/images/home/selectfile.png"
              alt=""
            /> -->
                <!-- <el-upload
                  :action="objData.host"
                  list-type="picture-card"
                  :before-upload="beforUploadFun"
                  :file-list="fileList"
                  :auto-upload="false"
                >
                  <i class="el-icon-plus"></i>
                </el-upload> -->
                <el-upload
                  :action="objData.host"
                  :data="objData"
                  multiple
                  :limit="3"
                  list-type="picture-card"
                  :before-upload="beforeUploadFun"
                  :on-progress="progressFun"
                  :on-remove="handleRemove"
                  :on-success="videoSuccess"
                  :on-change="handleChange"
                  :file-list="fileList"
                >
                  <!-- <i class="el-icon-plus" v-if="showAddSource"></i> -->
                  <div
                    slot="trigger"
                    class="add-upload-box"
                    v-if="showAddSource"
                  >
                    <i class="el-icon-plus"></i>
                  </div>
                  <!-- <el-button slot="trigger" size="small" type="primary"
                    >选取文件</el-button
                  > -->
                </el-upload>
                <!-- <el-dialog
                  :visible.sync="videodialogVisible"
                  :append-to-body="true"
                >
                  <video
                    id="upvideo"
                    width="100%"
                    autoplay
                    height="500"
                    :src="dialogImageUrl"
                    controls
                  ></video>
                </el-dialog> -->
              </div>
            </el-form-item>
            <!-- 联系方式: -->
            <el-form-item>
              <p class="c3 f18">联系方式(选填)</p>
              <el-input
                class="mt16"
                v-model="phone"
                placeholder="手机号/邮箱/QQ号"
              ></el-input>
            </el-form-item>
            <!-- 联系方式:结束 -->
            <!-- 提交开始 -->
            <el-form-item>
              <el-button class="commit cf f16" @click="submitForm('ruleForm')"
                >确  认</el-button
              >
            </el-form-item>
          </el-form>
        </el-row>
        <!-- 关于我们内容 end -->
      </el-row>
    </el-row>
    <!-- 右侧保修 客服 -->
    <el-row class="fix-right">
      <el-row class="item2">
        <el-button type="text" @click="assderVisible = true">
          <img src="@/assets/images/home/kefu.png" alt="" />
          <p>联系客服</p>
        </el-button>
      </el-row>
    </el-row>
    <!-- 弹窗 start -->
    <el-dialog
      :visible.sync="assderVisible"
      width="600px"
      :show-close="false"
      class="assderTc"
    >
      <span slot="title" class="title">
        <p class="fs18 font-33">联系平台</p>
        <img
          src="@/assets/images/home/close.png"
          @click="assderVisible = false"
          class="hand"
        />
      </span>

      <div class="ja-c fs14 font-66 w100">
        <p>
          <img src="@/assets/images/home/phone.png" alt="" />
          <span>13712345678</span>
        </p>
        <p>
          <img src="@/assets/images/home/weixin.png" alt="" />
          <span>13712345678</span>
        </p>
        <p>
          <img src="@/assets/images/home/qq.png" alt="" />
          <span>13712345678</span>
        </p>
        <p>
          <img src="@/assets/images/home/email.png" alt="" />
          <span>13712345678</span>
        </p>
      </div>
    </el-dialog>
    <!-- 弹窗结束 -->
  </div>
</template>

<script>
let flag = true;
import Header from "@/components/home/Header.vue";
import Nav from "@/components/home/Nav.vue";
import Footer from "@/components/home/Footer.vue";
export default {
  components: {
    Header,
    Nav,
    Footer,
  },
  data() {
    var validateFeedfackContant = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入您的意见或反馈"));
      }
      callback();
    };
    return {
      active: 0, //反馈类型
      dialogVisible: false,
      assderVisible: false, //客服弹窗
      phone: "", //联系方式
      ruleForm: {
        feedfackContant: "", //反馈内容
        phone: "", //联系电话
      },
      rules: {
        feedfackContant: [
          { validator: validateFeedfackContant, trigger: "blur" },
        ],
      },

      feedBackList: [], //意见反馈列表
      selectId: "", //选中意见的id

      // 图片上传

      fileList: [],

      objData: {
        OSSAccessKeyId: "", //Bucket拥有者的AccessKey ID
        policy: "", //Policy规定了请求表单域的合法性
        Signature: "", //	根据AccessKey Secret和Policy计算的签名信息
        key: "", //	上传Object的名称  文件名
        host: "", //后台返回  类似https://tulingmall-xushu.com
        dir: "", //上传地址   类似tuling-test/2021-02   用户上传文件时指定的前缀 后台加过斜杠则不用再加
      },

      showConText: {},

      upImg: [], //上传后所有的图片

      upVideo: [], //上传后所有的视频

      videodialogVisible: false,
      dialogImageUrl: "",

      showAddSource: true, //添加按钮显示隐藏
    };
  },

  mounted() {},

  created() {
    // 反馈类型-分页列表查询-所有反馈类型
    this.getFeedbackList();
    //根据分组查询系统配置
    this.getConfig();
  },

  methods: {
    // 图片上传方法
    videoSuccess(res, file, fileList) {
      // this.findvideocover(file.url, file);
    },

    // 图片上传前函数
    beforeUploadFun(file) {
      console.log("上传前函数", file);
      // 如果用户第一次上传了一张图片  那么第二次一定也要是一张图片  否则提示用户
      var fileType = file.type.split("/")[0];
      if (fileType != "image") {
        this.$message.info("请上传图片");
        this.fileList.pop();
        return false;
      }

      if (this.upImg.length) {
        // 用户已经不是第一次上传了  判断一定要是图片才可以
        // var imglist = ["png", "jpg", "jpeg", "bmp", "gif", "webp"];
        var fileType = file.type.split("/")[0];
        // let res = imglist.some((item) => {
        //   return item == fileType;
        // });
        // console.log(res, "结果");
        if (fileType != "image") {
          // 上传的不是图片
          // console.log("结果是图片");
          this.$message.info("请继续上传图片");
          this.fileList.pop();
          return false;
        }
      }

      return new Promise((rs, rj) => {
        //var filename = file.name;
        this.$API.getOssConfig().then((res) => {
          if (res.code == 1) {
            this.showConText = res.data;
            this.objData.OSSAccessKeyId = this.showConText.accessid;
            this.objData.policy = this.showConText.policy; //不确定
            this.objData.Signature = this.showConText.signature; //不确定
            this.objData.host = this.showConText.host;
            var suffix = file.name.substring(file.name.lastIndexOf(".") + 1);
            var filename = this.showConText.fileName + "." + suffix;
            this.objData.key = filename; //不确定
            //this.objData.success_action_status = "200";
            //this.objData.dir = this.showConText.fileName;
            // console.log("上传成功的文件访问路径:");
            //console.log(`${this.objData.host}/${this.objData.dir}/${filename}`);
            // file.viewUrl = `${this.objData.host}/${this.objData.dir}/${filename}`;
            var videaUrl = this.objData.host + "/" + filename;
            console.log(videaUrl, "videaurl");

            // 如果用户上传的是视频  控制只能上传一个
            // 匹配 视频
            var urlArr = videaUrl.split(".");
            let houzhui = urlArr[urlArr.length - 1];
            // 视频格式
            var videolist = ["mp4", "m2v", "mkv"];
            let result = videolist.some(function (item) {
              return item == houzhui;
            });
            // 图片格式
            var imglist = ["png", "jpg", "jpeg", "bmp", "gif", "webp"];
            let resultImg = imglist.some(function (item) {
              return item == houzhui;
            });
            // 上传的是视频的处理
            if (result) {
              // result = "video";
              // return result;
              // 上传的是视频
              this.showAddSource = false;
              this.upVideo.push(videaUrl);
            }

            // 上传的是图片的处理
            if (resultImg) {
              // result = "video";
              // return result;
              // 上传的是视频
              // this.showAddSource = false;
              // console.log("上传的是图片");
              this.upImg.push(videaUrl);
            }

            // 如果用户上传的是图片  控制可以上传三个
            // 匹配 视频
            // var urlArr = videaUrl.split(".");
            // let houzhui = urlArr[urlArr.length - 1];
            // var videolist = ["mp4", "m2v", "mkv"];
            // let result = videolist.some(function (item) {
            //   return item == houzhui;
            // });
            // if (result) {
            //   // result = "video";
            //   // return result;
            //   // 上传的是视频
            //   this.showAddSource = false;
            // }

            // this.upImg.push(videaUrl);
            if (this.upImg.length == 3) {
              this.showAddSource = false;
            }
            console.log(this.upImg);
            rs(true);
          }
        });
      });
    },

    //根据分组查询系统配置
    getOssConfig() {
      this.$API
        .getOssConfig()
        .then((res) => {
          if (res.code == 1) {
            this.showConText = res.data;
            // console.log(this.showConText);
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 图片上传
    handleChange(file, fileList) {
      this.fileList = fileList.slice(-3);
    },
    //根据分组查询系统配置
    getConfig() {
      let paramData = {
        configGroup: "oss",
      };
      this.$API
        .getConfig(paramData)
        .then((res) => {
          if (res.code == 1) {
            this.configObj = res.data;
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 反馈类型-分页列表查询-所有反馈类型
    getFeedbackList() {
      this.$API
        .getFeedbackList()
        .then((res) => {
          if (res.code == 1) {
            this.feedBackList = res.data.records;
            this.selectId = this.feedBackList[0].id;
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    progressFun() {
      // console.log("正在上传");
    },

    //删除图片
    handleRemove(file, fileList) {
      console.log(file.url, "fileurl");
      console.log(this.fileList, "filelist");
      this.fileList.forEach((item, index) => {
        // console.log(item.url);
        if (item.url == file.url) {
          this.fileList.splice(index, 1);
          console.log(index);
          this.upImg = this.upImg.filter((item, delIndex) => {
            return delIndex != index;
          });
          console.log(this.upImg, "upImg");
        }
      });
      this.showAddSource = true;
    },
    //上传图片
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
      console.log(file);
    },
    // 更改反馈类型
    changeActive(index, item) {
      this.active = index;
      this.selectId = item.id;
    },
    // 提交反馈
    submitForm(formName) {
      if (!flag) {
        return;
      }
      // 提交
      this.$refs[formName].validate((valid) => {
        if (valid) {
          flag = false;

          // 图片上传
          // 检验是否上传图片
          if (this.upImg.length == 0) {
            // 如果图片数组长度为零并且视频数组不为零
            this.$message({
              message: "请上传图片",
              type: "warning",
            });
            return;
          }
          let imgList = [];
          this.upImg.forEach((item) => {
            imgList.push(item.url);
          });

          // 图片上传参数
          let filelistStr = this.upImg.map((item) => item).join(",");

          let paramData = {
            id: this.selectId, //选中意见id
            content: this.ruleForm.feedfackContant, //反馈内容
            contactInformation: this.phone,
            imgs: filelistStr,
          };
          this.$API
            .repairIntentionApi(paramData)
            .then((res) => {
              if (res.code == 1) {
                flag = true;
                this.$message({
                  message: "新增成功",
                  type: "success",
                });
                setTimeout(() => {
                  this.$router.push({ name: "home" });
                }, 500);
              } else {
                flag = true;
                this.$message.info(res.msg);
              }
            })
            .catch((err) => {
              flag = true;
              console.log(err);
            });

          // alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 上传文件
    uploadFile() {},
    // 控制客服弹窗
    assderHandle() {
      this.assderVisible = false;
    },
    // 反馈记录
    backHandle() {},
  },
};
</script>

<style lang="scss" scoped>
.el-form{
  margin-top: 20px;
}
.el-input {
  width: 260px;
}
.contaner {
    width: auto;
    min-width: auto;
    margin: 0 auto;
}
.feedback-contaner {
  .add-upload-box {
    width: 83px !important;
    height: 83px !important;
    line-height: 83px !important;
    border: 1px dotted #999;
  }
  .el-upload--picture-card {
    width: 0px;
    height: 0px;
    margin: 0px;
    padding: 0px;
    // opacity: 0px;
    color: #ffffff;
    border: none !important;
    // width: 83px !important;
    // height: 83px !important;
    // line-height: 83px !important;
    // display: none;
  }
  .el-form-item__content {
    margin-left: 0px !important;
    line-height: auto;
  }
  .el-textarea,
  .el-textarea__inner {
    width: 400px;
    // height: 128px !important;
  }
  .el-input__inner {
    width: 260px;
  }
  width: 100%;
  height: 100%;
  .about-box {
    // min-width: 1200px;
    // width: 100%;
    box-sizing: border-box;
    // padding: 32px;
    margin-top: 20px;
    margin-left: 40px;

    background: #f8f8f8;
    .contaner {
      background: #ffffff;
      box-sizing: border-box;
      // padding: 32px;
    }
  }
  .about {
    .header {
      border-bottom: 1px solid #eeeeee;
      padding-bottom: 25px;
    }
    .left {
      font-size: 22px;
      font-weight: bold;
      color: #333333;
    }
    .right {
      font-size: 14px;
      color: #333333;
    }
  }
  //   按钮组
  .btn {
    margin-top: 17px;
    button {
      width: 128px;
      height: 40px;
      background: #ffffff;
      border-radius: 8px 8px 8px 8px;
      border: 1px solid #999999;
      &.active {
        border: 1px solid #ff0e1e;
        color: #ff0015;
      }
    }
  }
  .img {
    img {
      width: 94px;
      height: 94px;
    }
  }
  .commit {
    width: 100px;
    display: block;
    // margin: 0 auto;
    margin-top: 22px;
    background: linear-gradient(45deg, #ed1b23 0%, #f15f64 100%);
    color: #ffffff;
    font-size: 14px;
  }
}
// 右侧保修 客服
.fix-right {
  cursor: pointer;
  width: 88px;
  border: 1px solid #ebebeb;
  position: fixed;
  z-index: 1000;
  right: 0;
  // bottom: 266px;
  top: 50%;
  transform: translateY(-50%);
  .item1 {
    background-color: #ffffff;
  }
  .item2 {
    background-color: #ed1b23;
    z-index: 999;
  }
  .item1,
  .item2 {
    height: 90px;
    text-align: center;
    box-sizing: border-box;
    padding-top: 9px;
    // padding-bottom: 16px;
    img {
      width: 24px;
      height: 24px;
    }
    p {
      color: #757575;
      font-size: 14px;
      margin-top: 12px;
    }
  }
  .item2 p {
    color: #ffffff;
  }
}
// 自定义弹窗样式
.assderTc {
  display: flex;
  align-items: center;
  .title {
    padding-top: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-size: 22px;
    font-weight: bold;
    color: #333333;
    img {
      width: 32px;
      height: 32px;
      position: absolute;
      top: 32px;
      right: 32px;
    }
  }
  ::v-deep .el-dialog__header {
    padding: 0;
  }
  ::v-deep .el-dialog {
    border-radius: 0 !important;
  }
  ::v-deep .el-dialog__body {
    padding: 0;
  }
  ::v-deep .el-dialog__footer {
    padding: 0;
  }
  ::v-deep .el-dialog {
    border-radius: 10px;
  }
  .ja-c {
    height: 311px;
    box-sizing: border-box;
    padding-left: 214px;
    padding-top: 52px;
    p {
      display: flex;
      align-items: center;
      margin-bottom: 32px;
      img {
        width: 30px;
        height: 30px;
      }
      span {
        font-size: 14px;
        color: #333333;
        margin-left: 16px;
      }
    }
  }

  .btnad {
    width: 112px;
    height: 40px;
    background: rgba(246, 247, 249, 1);
    border: 1px solid #ecedef;
    opacity: 1;
    border-radius: 20px;
  }
  .btnsad {
    color: #333;
    width: 112px;
    height: 40px;
    background: rgba(255, 195, 44, 1);
    opacity: 1;
    border-radius: 20px;
  }
}
::v-deep .el-upload--picture-card {
  width: 85px !important;
  height: 85px !important;
  line-height: 94px !important;
}
</style>